import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  deleteFilter,
  setFilter,
  Param,
  selectSearchForm
} from './searchSlice';
import {
  useLocation
} from "react-router-dom";
import { Icon } from '@iconify/react';

function FacetDropdownItem(props: any) {
  const fusionFilters = useAppSelector(selectSearchForm).filters;
  const dispatch = useAppDispatch();

  var facetName = props.facetName;
  var facetValue = props.facetValue;
  var checked = facetValue.checked;
  var multiselect = props.multiselect;
  var name = props.facetValue.name;

  var display = (facetValue.label)
    ? facetValue.label + " (" + facetValue.count + ")"
    : name + " (" + facetValue.count + ")";

  const handleCheckboxClick = (e: any) => {
    e.preventDefault();

    var filterValuesForGivenKey: any[] = [];
    if (fusionFilters[props.facetName] != null && fusionFilters[facetName] instanceof Array) {
      filterValuesForGivenKey = Array.from(fusionFilters[facetName]);
    }
    else if (fusionFilters[props.facetName] != null && typeof (fusionFilters[facetName]) == "string") {
      filterValuesForGivenKey = [fusionFilters[facetName]];
    }

    const filterValueExists = filterValuesForGivenKey.includes(name);
    if (!filterValueExists) {
      if (multiselect) {
        filterValuesForGivenKey.push(name);
        const param: Param = {
          name: props.facetName,
          value: filterValuesForGivenKey
        }
        dispatch(setFilter(param));
      }
      else {
        const param: Param = {
          name: props.facetName,
          value: [name]
        }
        dispatch(setFilter(param));
      }
    }
    else {

      //a new array with the facetValue has been removed from original array
      const newArrayWithoutFacetValue = filterValuesForGivenKey.filter(function (item) {
        return item !== name
      })

      if(newArrayWithoutFacetValue.length > 0){
        const param = {
          name: props.facetName,
          value: newArrayWithoutFacetValue
        }
        dispatch(setFilter(param));
      }  
      else{
        dispatch(deleteFilter(props.facetName));
      }
    }
  };
  return (
    <li>
      {checked

        ? <input type="checkbox" checked={true} onChange={handleCheckboxClick} />
        : <input type="checkbox" checked={false} onChange={handleCheckboxClick} />
      }

      <a href="/" onClick={handleCheckboxClick}>{display}</a>
      <span className="checkmark"></span>
    </li>
  )
}


function showDisplayFacet(facetName: string, facetLabelsMap:any) {
  if (facetLabelsMap[facetName]) {
    return facetLabelsMap[facetName];
  }
  return facetName.replace("as_", "").replace("_ss", "").replaceAll("_", " ");
}




function FacetField(props: any) {
  var multiselect = (props.facetName === "category") ? false : true;
  const facetValues: any = props.facetValues;
  const facetName = props.facetName;
  const solrResponse = props.solrResponse;
  const queryFacetWeightsMaps = (solrResponse.b2b && solrResponse.b2b.query_facet_weights) ? solrResponse.b2b.query_facet_weights : {}
  const userFacetWeightsMap = (solrResponse.b2b && solrResponse.b2b.user_facet_weights) ? solrResponse.b2b.user_facet_weights : {}
  const isInQueryFacetWeights = queryFacetWeightsMaps[facetName] ? true : false;
  const isInUserFacetWeights = userFacetWeightsMap[facetName] ? true : false;
  const isDynamicFacetBoost = isInQueryFacetWeights || isInUserFacetWeights;
  const facetLabelsMap = props.facetLabelsMap;
  var checkedFacetValues = facetValues.filter((item:any)=>{return item.checked});
  var isSelected = checkedFacetValues.length > 0;
  const [show, setShow] = useState(props.index < 5);

  const handleShowHideButton = (e: any) => {
    e.preventDefault();
    setShow(!show);
  }
  return (
    props.facetValues.length < 1
      ? null
      : (
        <React.Fragment>
          <div className="widget_list widget_categories">
            <div>
              {(show || isSelected)
                ? <a href="/" onClick={handleShowHideButton}><Icon icon="material-symbols:keyboard-arrow-down" width="25" /></a>
                : <a href="/" onClick={handleShowHideButton}><Icon icon="material-symbols:keyboard-arrow-right" width="25" /></a>
              }
              <span className="facet_name">{showDisplayFacet(props.facetName, facetLabelsMap)}</span>

              {
                (isDynamicFacetBoost)
                  ? <div className="facet_boost "><Icon icon="mdi:arrow-up-bold-box" color="rgb(136, 214, 235)" width="28" /></div>
                  : ""
              }
            </div>
            {(show || isSelected)
              ? <><ul>
              {facetValues.slice(0, 100).map((facetValue: any) => (
                <FacetDropdownItem
                  key={facetValue.name}
                  facetName={props.facetName}
                  facetValue={facetValue}
                  multiselect={multiselect}
                />
              ))}
            </ul></>
              : <></>
            }
          </div>
        </React.Fragment>
      )
  );
}







export interface FacetValue {
  name: string;
  count: number;
  label?: string;
  url?: string;
  checked: boolean;
}
export function LeftFilters(props: any) {
  const solrResponse = props.solrResponse;
  const facetCounts = (solrResponse && solrResponse.facet_counts)
    ? solrResponse.facet_counts
    : {}
  const facetFields = (facetCounts && facetCounts.facet_fields)
    ? facetCounts.facet_fields
    :
    {
    };

  const facetFieldsKeys = []
  var facetFieldsMap: any = {}
  const searchForm = useAppSelector(selectSearchForm);
  const fusionFilters: any = searchForm.filters;

  const location = useLocation();
  const unique_location_key = location.pathname + location.search;
  const facetLabels = (solrResponse && solrResponse.fusion && solrResponse.fusion.facet_labels) ? solrResponse.fusion.facet_labels : []
  const keyValuePairs = facetLabels[0].split(',').map((pair: string) => pair.split(':'));
  const facetLabelsMap = keyValuePairs.reduce((obj: { [x: string]: any; }, [key, value]: any) => {
    obj[key] = value;
    return obj;
  }, {});

  for (var key of Object.keys(facetFields)) {
    facetFieldsKeys.push(key)
    var facetArray: any = [];
    var facet = facetFields[key];
    //build facet values object that comprises facetField name and count 
    for (var i = 0; i < facet.length; i++) {
      const facetValue = facet[i];

      const facetName = facetValue[0];
      var filterValuesForGivenKey = (fusionFilters[key]) ? fusionFilters[key] : [];
      var filterValueExists = false;
      if (typeof (filterValuesForGivenKey) === "string") {
        filterValueExists = (filterValuesForGivenKey === facetName)
      }
      else {
        filterValueExists = (filterValuesForGivenKey.includes(facetName))
      }

      const facetValueObj: FacetValue = {
        name: facetName,
        count: facetValue[1],
        checked: filterValueExists
      }

      if (facetValue.length >= 3) {
        facetValueObj.label = facetValue[2];
      }
      if (facetValue.length >= 4) {
        facetValueObj.url = facetValue[3];
      }

      facetArray.push(facetValueObj);
    }
    facetFieldsMap[key] = facetArray;
  }

  /*
  We expect the category to always be the first.  If it's not 
  the first element, it's likely because the user has filtered or drilled down
  to the leaf, and the response from Fusion no longer contains any more 
  filters to further drill down.  We'll forcibly insert it anyway to be
  able to display an inline breadcrumb to link back up the category tree
  */

  if (facetFieldsKeys[0] !== "category") {
    facetFieldsKeys.unshift("category")
    facetFieldsMap["category"] = []
  }

  return (
    <React.Fragment>
      <div className="col-lg-3 col-md-12">
        <aside className="sidebar_widget">
          <div className="widget_inner">
            {
              facetFieldsKeys.map((key, index) =>
                <FacetField key={"facetfield-" + unique_location_key + '-' + key} facetName={key}
                    facetValues={facetFieldsMap[key]} solrResponse={solrResponse} facetLabelsMap={facetLabelsMap}
                    index={index}
                  />
              )
            }
          </div>
        </aside>
      </div>
    </React.Fragment>
  )
}
export default LeftFilters
