import {  useAppSelector } from '../../app/hooks';
import {
    ChatMessage,
    selectChatMessages,
    selectSearchForm,  getErrorMessages
} from './searchSlice';
import { getCurrentEnvironment } from "./EnvironmentConfiguration";
import ReactJson from 'react-json-view';

function createMarkup(message:string) {
    return {__html: message};
  }

export default function ChatDebugPanel() {
    const chatMessages = useAppSelector(selectChatMessages);
    const searchForm = useAppSelector(selectSearchForm);

    const environment = getCurrentEnvironment()
    const error_messsages = useAppSelector(getErrorMessages)

    return (
        <>
            <div className="error_messages">
                {error_messsages.map((message:any, index) => {
                  return(
                    <div className={"alert " + message.type } role="alert" key={message.message}>
                      {message.message}
                    </div>
                  )
                })}
            </div>
            <div className="py-3">
            <h4>Chat Messages</h4>
            {(chatMessages.length === 0)
            ? <div>No messages</div>
            : <></>
            }
                        <div className="container">

            {
                chatMessages.map((message: ChatMessage, index: number) => {
                    return (
                            <div className="row">
                                <div className="col-2">
                                    {message.role}
                                </div>
                                <div className="col-10">
                                    <div dangerouslySetInnerHTML={createMarkup(message.content)} />
                                </div>
                            </div>
                    )
                })
            }
                        </div>
            </div>
            <div className="py-3">
                
            <h4>Search Form</h4>
            {
                <ReactJson src={searchForm} collapsed={false} />
            }
            </div>
            <div className="py-3">
            <h3>Other Info</h3>
            Environment: {environment}
            </div>
        </>
    )

}