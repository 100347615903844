import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchCount } from './SearchAPI';
import { RelevanceRule, BoostFunctionState} from './custom_types';
export interface SearchState {
  value: number;
  browserSearchAppLocation: any
  stateSearchAppLocation: any
  status: 'idle' | 'loading' | 'failed';
  user: {},
  frontend: any,
  contentType: string
  relevance_rules: RelevanceRule[],
  pdp_copilot: any
  chat_messages: ChatMessage[],
  search_form: any,
  previous_search_form: any,
  last_chat_response: string,
  chat_is_loading: boolean,
  error_messages: any[],
  alternate_options: any,
  persisted: {
    model: string,
    hidden: boolean,
    auth: {
      authenticated: boolean,
      username: string,
      password: string
    }
  }
}

export interface SearchAppLocation {
  pathname: string,
  queryParams: any
  filters: any
}

export interface ChatMessage {
  role: string,
  content: any
}

export interface Param {
  name: string,
  value: any
}

export interface RelevanceRuleChangeParam{
  id: string,
  ruleState: RelevanceRule | BoostFunctionState
}

export const initialState: SearchState = {
  value: 0,
  status: 'idle',
  user: {},
  browserSearchAppLocation: {},
  stateSearchAppLocation: {},
  frontend: {relevance: false},
  contentType: "products",
  relevance_rules: [],
  pdp_copilot: {},
  chat_messages: [],
  last_chat_response: "",
  chat_is_loading: false,
  search_form: {
    "query_parameters": {
      "q": "*:*"
    },
    "filters": {
    }
  },
  previous_search_form: {},
  error_messages: [],
  alternate_options: {},
  persisted: {
    model: "3.5",
    hidden: true,
    auth: {
      authenticated: false,
      username: "",
      password: ""
    }
  }
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const searchSlice = createSlice({
  name: 'counter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
      state.stateSearchAppLocation.queryParams['q'] = state.value;

    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    replaceBrowserSearchAppLocation: (state, action: PayloadAction<Object>) => {
      state.browserSearchAppLocation = action.payload;
    },
    replaceStateSearchAppLocation: (state, action: PayloadAction<Object>) => {
      state.stateSearchAppLocation = action.payload;
    },
    setUser: (state, action: PayloadAction<Object>) => {
      state.user = action.payload;
    },
    setModel: (state, action: PayloadAction<string>) => {
      state.persisted.model = action.payload;
    }, 
    setHidden: (state, action: PayloadAction<boolean>) => {
      state.persisted.hidden = action.payload;
    },    
    setPDPCoPilot: (state, action: PayloadAction<Object>) => {
      state.pdp_copilot = action.payload;
    },
    toggleFrontEndRelevance: (state) => {
      const currentState = state.frontend.relevance;
      state.frontend.relevance = !currentState;
    },
    addChatMessage: (state, action: PayloadAction<ChatMessage>) => {
      var currentMessages = state.chat_messages;
      currentMessages.push(action.payload);
      state.chat_messages = currentMessages;
    },
    replaceChatMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.chat_messages = action.payload;
    },
    setSearchForm:(state, action: PayloadAction<any>) => {
      state.search_form = action.payload;
    },
    setPreviousSearchForm:(state, action: PayloadAction<any>) => {
      state.previous_search_form = action.payload;
    },
    setLastChatResponse:(state, action: PayloadAction<string>) => {
      state.last_chat_response = action.payload
    },
    setAlternateOptions:(state, action: PayloadAction<any>) => {
      state.alternate_options = action.payload
    },
    setChatIsLoading:(state, action: PayloadAction<boolean>) => {
      state.chat_is_loading = action.payload
    },
    setErrorMessages:(state, action: PayloadAction<any[]>) => {
      state.error_messages = action.payload
    },
    modifyRelevanceRule:(state,action:PayloadAction<RelevanceRuleChangeParam>) =>{
      const id = action.payload.id;
      const newState = action.payload.ruleState;
      var ruleIndexFound:any = undefined;

      //search for the rule we're looking to update by comparing ID's
      for(var index in state.relevance_rules){
        if(state.relevance_rules[index].id === id){
          ruleIndexFound = index;
        }
      }

      //If we found the item we're looking for, perform the update
      if(ruleIndexFound){
        state.relevance_rules[ruleIndexFound] = newState
      }
    } ,
    deleteRelevancyRule:(state,action:PayloadAction<RelevanceRuleChangeParam>) =>{
      const id = action.payload.id;
      var ruleIndexFound:any = undefined;

      //search for the rule we're looking to update by comparing ID's
      for(var index in state.relevance_rules){
        if(state.relevance_rules[index].id === id){
          ruleIndexFound = index;
        }
      }

      //If we found the item we're looking for, perform the update
      if(ruleIndexFound){
        state.relevance_rules.splice(ruleIndexFound, 1);
      }
    } ,
    addRelevanceRule:(state,action:PayloadAction<RelevanceRule>) =>{
       state.relevance_rules.push(action.payload)
    } ,
    issueNewSearch: (state, action: PayloadAction<string>) => {
      const query = action.payload;
      //console.log("setting query params.q to " + query);

      state.stateSearchAppLocation.pathname = "/search"
      state.stateSearchAppLocation.queryParams.q = query;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
      delete  state.stateSearchAppLocation.queryParams['sort'] ;
      state.stateSearchAppLocation.filters = {};
      state.contentType = "products";
    },
    issueNewContentSearch: (state, action: PayloadAction<string>) => {
      const query = action.payload;
      console.log("setting query params.q to " + query);

      state.stateSearchAppLocation.pathname = "/content"
      state.stateSearchAppLocation.queryParams.q = query;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
      delete  state.stateSearchAppLocation.queryParams['sort'] ;
      state.stateSearchAppLocation.filters = {};
      state.contentType = "content";

    },
    setNavigationParam: (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.stateSearchAppLocation.queryParams[param.name] = param.value;
    },
    setSort: (state, action: PayloadAction<string>) => {
      const sortKey:string = action.payload;
      if(sortKey === null || sortKey === ""){
        delete state.stateSearchAppLocation.queryParams['sort'];
      }
      else{
        state.stateSearchAppLocation.queryParams['sort'] = sortKey;
      }
      delete state.stateSearchAppLocation.queryParams['start'];
    },
    setFilter:  (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.search_form.filters[param.name] = param.value;
      //delete  state.search_form.queryParams['start'] ;
    },
    deleteFilter: (state, action: PayloadAction<string>) => {
      delete state.search_form.filters[action.payload];
    },
    browseCategoryPath:  (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.stateSearchAppLocation.pathname = param.value;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
      delete  state.stateSearchAppLocation.queryParams['q'] ;
    },
    browseMenuPath:  (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.stateSearchAppLocation.pathname = param.value;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
      delete  state.stateSearchAppLocation.queryParams['q'] ;
      state.stateSearchAppLocation.filters = {};
    },
    searchCategoryPath:  (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.stateSearchAppLocation.pathname = param.value;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
    },
    setAuth: (state, action) => {
      state.persisted.auth.username = action.payload.username;
      state.persisted.auth.password = action.payload.password;
    },
    setAuthTrue: (state, action) => {
      state.persisted.auth.authenticated = true;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value += action.payload;
      });
  },
});

export const { increment, decrement, incrementByAmount, setUser, 
  issueNewSearch, setNavigationParam, setSort, 
  replaceBrowserSearchAppLocation,
  replaceStateSearchAppLocation,
  setFilter, browseCategoryPath, searchCategoryPath, browseMenuPath,
  toggleFrontEndRelevance, issueNewContentSearch, modifyRelevanceRule, 
  addRelevanceRule, deleteRelevancyRule, setPDPCoPilot, addChatMessage,
  setSearchForm, setLastChatResponse, replaceChatMessages, deleteFilter, setChatIsLoading,
  setPreviousSearchForm, setErrorMessages, setAlternateOptions, setModel, setHidden,
  setAuth, setAuthTrue
  } = searchSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.search.value;

export const selectBrowserSearchAppLocation = (state: RootState) => state.search.browserSearchAppLocation;
export const selectStateSearchAppLocation = (state: RootState) => state.search.stateSearchAppLocation;
export const selectUser = (state: RootState) => state.search.user;
export const selectFrontEnd = (state: RootState) => state.search.frontend;
export const selectContentType = (state: RootState) => state.search.contentType;
export const selectRelevanceRules = (state: RootState) => state.search.relevance_rules;
export const selectPDPCoPilot = (state: RootState) => state.search.pdp_copilot;
export const selectChatMessages = (state: RootState) => state.search.chat_messages;
export const selectSearchForm = (state: RootState) => state.search.search_form;
export const selectPreviousSearchForm = (state: RootState) => state.search.previous_search_form;
export const selectLastChatResponse = (state: RootState) => state.search.last_chat_response;
export const selectChatIsLoading = (state: RootState) => state.search.chat_is_loading;
export const getErrorMessages = (state: RootState) => state.search.error_messages;
export const getAlternateOptions = (state: RootState) => state.search.alternate_options;
export const selectModel = (state: RootState) => state.search.persisted.model;
export const selectHidden = (state: RootState) => state.search.persisted.hidden;
export const selectAuth = (state: RootState) => state.search.persisted.auth;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount: number): AppThunk => (
  dispatch,
  getState
) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default searchSlice.reducer;
