import React, { useState,  } from 'react';
import { useDispatch } from 'react-redux';
import { setAuth, setAuthTrue } from './features/search/searchSlice';
import { useAuthenticateMutation } from './features/search/ChatAPI'

export function Login() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState({
    error: false,
    message: ""
  });

  const dispatch = useDispatch();

  const [authenticationTrigger] = useAuthenticateMutation();

  const authenticate = (u:string,p:string) => (event:any) => {
    event.preventDefault();
    const payload = {
      username: u,
      password: p
    }

    dispatch(setAuth(payload));
    authenticationTrigger(payload)
    .unwrap()
    .then((fulfilled) => {
      if(fulfilled.authenticated === true){
        dispatch(setAuthTrue({}));
      }
      else{
        setStatus({
          error: true,
          message: "Authentication failed"
        });
      }
    })
    .catch((error) => {
      setStatus({
        error: true,
        message: "Authentication failed"
      });
    })
  }

  return (
    <React.Fragment>
     <section className="section-content">
        <div className="container">
          <h1 className="my-4">Sign In</h1>
          <div className="row justify-content-center">
            <div className="col-4 py-4">
              <Status status={status}/>
              <form onSubmit={authenticate(username, password)}>
                <div className="form-group">
                  <label>Username</label>
                  <input type="text" className="form-control" onChange={(e) => setUsername(e.target.value)}  
                    id="username" aria-describedby="emailHelp" placeholder="Enter username" 
                     />
                </div>
                <div className="form-group py-4">
                  <label >Password</label>
                  <input type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} 
                   id="password" placeholder="Password" />
                </div>
                <button type="submit" className="btn btn-primary" >Submit</button>
              </form>
            </div >
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
export default Login


export function Status(props:any){
  const status = props.status;
  return(
    (status.error === true && status.message !== "")
    ?
    <div className="alert alert-danger" role="alert">
      {status.message}
    </div>
    : null
  )
}
