
import React, { } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useChatMutation } from './ChatAPI'
import {
    ChatMessage,
    setSearchForm,
    addChatMessage,
    selectSearchForm,
    selectChatMessages,
    setLastChatResponse,
    setChatIsLoading,
    setPreviousSearchForm,
    replaceChatMessages,
    selectPreviousSearchForm,
    selectChatIsLoading,
    setErrorMessages,
    setAlternateOptions,
    selectModel,
  } from './searchSlice';

export function ChatBar() {
    const [question, setQuestion] = React.useState("");
    const dispatch = useAppDispatch();
    const history = useAppSelector(selectChatMessages);
    const [sendChatMessages] = useChatMutation()
    const searchForm = useAppSelector(selectSearchForm);
    const chatIsLoading = useAppSelector(selectChatIsLoading);
    const previousSearchForm = useAppSelector(selectPreviousSearchForm);
    const modelFromState = useAppSelector(selectModel);

    const handleTextFieldChange = (event: any) => {
        if (event.key === "Escape") {
            ;
        }
        else {
            setQuestion(event.target.value);
        }
    };

    const handleOnKeyDown = (messages: ChatMessage[]) => (event: any) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            console.log("enter detected " + event.target.value);
            
            if(question.trim() === "" && chatIsLoading === true){
                console.log("Prevented empty question while chat is loading")
                return;
            }
            else if(question.trim() === "" && chatIsLoading === false && history.length === 0){
                console.log("Prevented empty question while chat is not loading, but messages list is empty")
                return;
            }
            else if(question.trim() === "" && chatIsLoading === false){
                console.log("resending last message");

                 // we don't want to include the last message given that's likely a response from the assistant
                const newHistory = Array.from(history.slice(0, history.length - 1));
                console.log("trimmed history is " + JSON.stringify(newHistory));

                dispatch(replaceChatMessages(newHistory))
                const payload = {
                    messages: newHistory,
                    search_form: previousSearchForm,
                    model: modelFromState
                }
                dispatch(setChatIsLoading(true));
                dispatch(setErrorMessages([]));

                sendChatMessages(payload).unwrap().then((result) => { 
                    console.log("received response in resend message")                    
                    const resultAsObject = result;
                    console.log("result: " + JSON.stringify(resultAsObject));
                    console.log("message:" + resultAsObject['message']);
                    const messageFromAssistant: ChatMessage = {
                        role: "assistant",
                        content: resultAsObject['message']
                    }
                    dispatch(addChatMessage(messageFromAssistant));
                    dispatch(setLastChatResponse(resultAsObject['message']));
                    const searchForm = resultAsObject['search_form'];
                    if(searchForm !== undefined){
                        dispatch(setSearchForm(searchForm));                    
                    }
                    if(resultAsObject['error_messages'] !== undefined){
                        dispatch(setErrorMessages(resultAsObject['error_messages']));
                    }
                    dispatch(setChatIsLoading(false));
                }).catch((error) => {
                    console.log("error: " + JSON.stringify(error));
                    dispatch(setErrorMessages([{message: "Error was encountered calling the chat service. " + JSON.stringify(error), type: "alert-danger"}]));
                    dispatch(setChatIsLoading(false));
                }); 
                return;
            }

            const message: ChatMessage = {
                role: "user",
                content: question
            };

            const messagesCopy = Array.from(messages); 

            messagesCopy.push(message);
            const payload = {
                messages: messagesCopy,
                search_form: searchForm,
                model: modelFromState
            }

            console.log("Calling add chat message");
            dispatch(addChatMessage(message));
            dispatch(setChatIsLoading(true));
            dispatch(setErrorMessages([]));
            dispatch(setAlternateOptions({}));

            sendChatMessages(payload).unwrap().then((result) => { 
                console.log("result line 65: " + result);
                const resultAsObject = result;
                console.log("result: " + JSON.stringify(resultAsObject));
                console.log("message:" + resultAsObject['message']);
                const messageFromAssistant: ChatMessage = {
                    role: "assistant",
                    content: resultAsObject['message']
                }
                dispatch(setLastChatResponse(resultAsObject['message']));
                dispatch(addChatMessage(messageFromAssistant));

                const searchFormFromResponse = resultAsObject['search_form'];
                if(searchFormFromResponse !== undefined){
                    dispatch(setPreviousSearchForm(searchForm));                    
                    dispatch(setSearchForm(searchFormFromResponse));                    
                }
                if(resultAsObject['error_messages'] !== undefined){
                    dispatch(setErrorMessages(resultAsObject['error_messages']));
                }
                console.log("Inspecting " + JSON.stringify(resultAsObject['alternate_options']))
                if(resultAsObject['alternate_options'] !== undefined){
                    console.log("here in line 135")
                    dispatch(setAlternateOptions(resultAsObject['alternate_options']));
                }
                dispatch(setChatIsLoading(false));

            }).catch((error) => {
                console.log("error: " + JSON.stringify(error));
                dispatch(setErrorMessages([{message: "Error was encountered calling the chat service. " + JSON.stringify(error), type: "alert-danger"}]));
                dispatch(setChatIsLoading(false));
            });
            setQuestion('');
        }
    };

    return (
        <>
            <div className="search_box">
                <textarea placeholder="Chat with Sales Associate ..." onKeyDown={handleOnKeyDown(history)} onChange={handleTextFieldChange} value={question}  cols={100}></textarea>
                <button type="submit"><i className="ion-ios-search-strong"></i></button>
            </div>
        </>
    );
}