import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import {useFusionMutation} from "./ChatAPI"
import {
    selectSearchForm
} from './searchSlice';
import ReactJson from 'react-json-view'
import LeftFilters from "./LeftFilters";
import ClampLines from 'react-clamp-lines';


function ProductItem(props: any) {
    const doc = props.doc;
    const imageUrl = props.doc.imageUrl_s;
    const summarySource = "Google";
    var summaryText = summarySource === "Google" ? doc.googleChatSummary_s : doc.chatSummary_s;
    if(summaryText !== undefined && summaryText.length > 0 && summarySource === "Google") {
        summaryText = summaryText + "  --- Generated by Google PaLM 2 --";
    }
    return (
        <div className="col-md-4 col-sm-6 col-lg-4">

            <div className="single_product">
                <div className="product_name grid_name">
                    <h3><a href="#" ><span className="manufacturer">{doc.manufacturer_s}</span> {doc.title_s}</a></h3>
                </div>
                <div className="product_thumb">
                    <a className="primary_img" href="#" ><img src={imageUrl} alt="" /></a>
                </div>
                <div className="product_content grid_content">
                    <div className="content_inner">
       <ClampLines
          text={summaryText}
          id="custom"
          lines={5}
          moreText="..."
          lessText="-"
          className="custom-class"
          ellipsis=""
          innerElement="span" />
                    {
                        /*
                    summarySource === "Google" ? <div className="summarySource">Generated by Google PaLM 2<img src="/img/icon/Google_PaLM_Logo.svg.png" width={25}/></div> : <></>
                    */
                    }

                    </div>
                </div>
                <div className="product_content list_content">
                    <div className="left_caption">
                        <div className="product_name">
                            <h3><a href="product-details.html">Cas Meque Metus Shoes Core i7 3.4GHz, 16GB DDR3 {JSON.stringify(doc.id)}</a></h3>
                        </div>
                        <div className="product_ratings">
                            <ul>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                            </ul>
                        </div>
                        <div className="product_desc">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis ad, iure incidunt. Ab consequatur temporibus non eveniet inventore doloremque necessitatibus sed, ducimus quisquam, ad asperiores </p>
                        </div>
                    </div>
                    <div className="right_caption">
                        <div className="text_available">
                            <p>availabe: <span>99 in stock</span></p>
                        </div>
                        <div className="price_box">
                            <span className="current_price">$160.00</span>
                            <span className="old_price">$420.00</span>
                        </div>
                        <div className="cart_links_btn">
                            <a href="/" title="add to cart">add to cart</a>
                        </div>
                        <div className="action_links_btn">
                            <ul>
                                <li className="quick_button"><a href="/" data-bs-toggle="modal" data-bs-target="#modal_box" title="quick view"> <span className="lnr lnr-magnifier"></span></a></li>
                                <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><span className="lnr lnr-heart"></span></a></li>
                                <li className="compare"><a href="compare.html" title="compare"><span className="lnr lnr-sync"></span></a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}



function RenderSearchResults(props: any) {
    var fusionResponse = props.fusionResponse;
    var numFound = (fusionResponse != null && fusionResponse['response'] != null && fusionResponse['response']['numFound']) ? fusionResponse['response']['numFound'] : 0;
    var docs: any[] = (fusionResponse != null && fusionResponse['response'] != null && fusionResponse['response']['docs']) ? fusionResponse['response']['docs'] : [];
    return(
        <>
        <div className="numfound">{numFound} results found</div>
        {(numFound === 0)
                ?
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="shop_title">
                                <h1>We're sorry.  There were no results found for your search  <span className="searchTerm">q goes here</span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                </div>
                :
                <div className="container">

                    <div className="row">
                        <LeftFilters solrResponse={fusionResponse} />
                        <div className="col-lg-9 col-md-12">

                            <div className="row shop_wrapper grid_3">
                                {
                                    docs.map((doc: any, index: number) => {
                                       return(
                                        <>
                                        <ProductItem doc={doc} key={doc.title_s + doc.imageUrl_s}/>
                                        </>
                                       )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export function Search(props: any) {
    const searchForm = useAppSelector(selectSearchForm);
    const [callFusion] = useFusionMutation()
    const [fusionResponse, setFusionResponse] = useState<any>();
    var show = fusionResponse != null && Object.keys(fusionResponse).length > 0;

    var searchFormCopy = JSON.parse(JSON.stringify(searchForm));
    searchFormCopy['query_parameters']['rows'] = 100;
    searchFormCopy['query_parameters']['json.nl'] = "arrarr";
    

    useEffect(() => {
        callFusion(searchFormCopy).unwrap().then((result) => { 
            setFusionResponse(result)
        }).catch((error) => {
            console.log("error: " + JSON.stringify(error));
        });
    
    }, [searchForm]);
    return (
        <React.Fragment>

            {(show)
            ? <>
            
              <RenderSearchResults fusionResponse={fusionResponse} />
              <ReactJson src={fusionResponse} collapsed={true} />       
            
            </>
            : <> </>
        }
        </React.Fragment>
    )
}
export default Search