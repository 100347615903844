import React, { useState } from 'react';
import {
    selectUser
} from './searchSlice';
import Modal from 'react-bootstrap/Modal'
import { useAppSelector } from '../../app/hooks';
import { ChatBar } from './ChatBar';

export function HeaderNoSearch() {
    const user: any = useAppSelector(selectUser);
    const firstName =
        (user != null && Object.keys(user).length > 0 && user.firstname !== "Non Login") ? user.account_firstname : "Signin";


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        setShow(true)
    };

    return (
        <React.Fragment>
            <header className="header_area">
                <div className="header_top">
                    <div className="container">
                        <div className="top_inner">
                            <div className="row align-items-center">
                                <div className="col-lg-10 col-md-6">

                                </div>
                                <div className="col-lg-2 col-md-6">
                                    <div className="top_right text-right">
                                        <ul>
                                            <li className="top_links"><a href="/" onClick={handleShow}><i className="ion-android-person"></i> Hello, {firstName} <i className="ion-ios-arrow-down"></i></a>
                                                <ul className="dropdown_links">
                                                    <li><a href="/" onClick={handleShow}>Switch User</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className="mini_cart">
                    <div className="cart_close">
                        <div className="cart_text">
                            <h3>cart</h3>
                        </div>
                        <div className="mini_cart_close">
                            <a href="/"><i className="ion-android-close"></i></a>
                        </div>
                    </div>
                    <div className="cart_item">
                        <div className="cart_img">
                            <a href="/"><img src="img/s-product/product.jpg" alt="" /></a>
                        </div>
                        <div className="cart_info">
                            <a href="/">JBL Flip 3 Splasroof Portable Bluetooth 2</a>

                            <span className="quantity">Qty: 1</span>
                            <span className="price_cart">$60.00</span>

                        </div>
                        <div className="cart_remove">
                            <a href="/"><i className="ion-android-close"></i></a>
                        </div>
                    </div>
                    <div className="cart_item">
                        <div className="cart_img">
                            <a href="/"><img src="img/s-product/product2.jpg" alt="" /></a>
                        </div>
                        <div className="cart_info">
                            <a href="/">Koss Porta Pro On Ear Headphones </a>
                            <span className="quantity">Qty: 1</span>
                            <span className="price_cart">$69.00</span>
                        </div>
                        <div className="cart_remove">
                            <a href="/"><i className="ion-android-close"></i></a>
                        </div>
                    </div>
                    <div className="mini_cart_table">
                        <div className="cart_total">
                            <span>Sub total:</span>
                            <span className="price">$138.00</span>
                        </div>
                        <div className="cart_total mt-10">
                            <span>total:</span>
                            <span className="price">$138.00</span>
                        </div>
                    </div>

                    <div className="mini_cart_footer">
                        <div className="cart_button">
                            <a href="cart.html">View cart</a>
                        </div>
                        <div className="cart_button">
                            <a className="active" href="checkout.html">Checkout</a>
                        </div>

                    </div>

                </div>
                <Modal show={show} onHide={handleClose} size="xl" >
                    <Modal.Header closeButton>
                        <Modal.Title>User Selector</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </header>

        </React.Fragment>
    )
}
export default HeaderNoSearch