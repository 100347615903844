import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import searchReducer from '../features/search/searchSlice';
import { ChatAPI } from '../features/search/ChatAPI';
import { initialState } from '../features/search/searchSlice';
import {localStorageMiddleware} from './LocalStorageMiddleware.js';

//read redux state.search.persisted from local storage and merge with initial state
const reduxState:any = localStorage.getItem("reduxStateConversationalBrowse");
const persistedSearchState = (reduxState != null) ? JSON.parse(reduxState) : initialState.persisted;
const combinedState = {...initialState};
combinedState.persisted = persistedSearchState;
const persistedState = {
  search: combinedState
}


export const store = configureStore({
  reducer: {
    search: searchReducer,
    [ChatAPI.reducerPath]: ChatAPI.reducer
  },
  preloadedState: persistedState,
    // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(ChatAPI.middleware)
    .concat(localStorageMiddleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
