/*
  Returns the environment name.
  
  Reads it from environment variable REACT_APP_B2B_ENV.   This can be set when running npm.  For example:
    
    REACT_APP_B2B_ENV=production npm start

  If the environment Variable REACT_APP_B2B_ENV is not set, it falls back to using process.env.NODE_ENV which is
  set by node depending on how you start it.  

  https://create-react-app.dev/docs/adding-custom-environment-variables/
  
  "When you run npm start, it is always equal to 'development', when you run npm test it is always equal to 'test', 
  and when you run npm run build to make a production bundle, it is always equal to 'production'. You cannot override NODE_ENV manually.
   This prevents developers from accidentally deploying a slow development build to production."
*/
export function getCurrentEnvironment(){
  const runtimeEnvironmentName = (process.env.REACT_APP_B2B_ENV != null) ? process.env.REACT_APP_B2B_ENV : process.env.NODE_ENV;
  return runtimeEnvironmentName;
}