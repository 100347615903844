import { useState } from 'react';
import {
  BrowserRouter as Router, 
} from "react-router-dom";
import ChatDebugPanel from "./features/search/ChatDebugPanel";
import Header from "./features/search/Header";
import HeaderNoSearch from './features/search/HeaderNoSearch';
import Search from "./features/search/Search";
import { Icon } from '@iconify/react';
import { selectLastChatResponse, deleteFilter, selectChatIsLoading, getErrorMessages, 
  getAlternateOptions, setFilter,  selectSearchForm, Param, selectHidden, setHidden,
  selectAuth
} from './features/search/searchSlice'
import Spinner from 'react-bootstrap/Spinner';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Carousel from 'react-bootstrap/Carousel';
import ModelSelector from './features/search/ModelSelector';
import Login from "./Login";

function AlternateOptions(params:any){
  const alternate_options:any = useAppSelector(getAlternateOptions)
  const fusion = ('fusion' in alternate_options)? alternate_options['fusion'] : {}
  const alternates:any = ('Alternates' in fusion && typeof(fusion['Alternates']) === 'object')
  ? fusion['Alternates'] : 
  {'alternate_options': []}
  const fixed_filter = (alternates['fixed_filter'] != null) ? alternates['fixed_filter']: ':'
  const [fixed_filter_name, fixed_filter_value] = fixed_filter.split(':');
  const fixed_filter_clean = fixed_filter_value.replaceAll('"','');
  function createMarkup(message:string) {
    return {__html: message};
  }
  return(
    <>
    <div className="alternate_msg" dangerouslySetInnerHTML={createMarkup(alternates.intro_msg)} />
    {
      alternates['alternate_options'].map((alternate:any) => {
        return(
          <>
          <div className="alternate_msg2">{alternate.msg}</div>
          <ul>
            {alternate.value_counts.map((value_count:any)=>{
              return(
               <AlternateLink fieldName={alternate.field} fieldValue={value_count.value} fixed_filter_name={fixed_filter_name} fixed_filter_value={fixed_filter_clean}/>
              )
            })}
          </ul>
          </>
        )
      })
    }
    </>
  )
}


function AlternateLink(params:any){
  const facetName = params.fieldName;
  const facetValue = params.fieldValue;
  const fusionFilters = useAppSelector(selectSearchForm).filters;
  const dispatch = useAppDispatch();
  const multiselect = true;
  const fixed_filter_name = params.fixed_filter_name;
  const fixed_filter_value = params.fixed_filter_value;

  const handleClick = (e: any) => {
    e.preventDefault();

    var filterValuesForGivenKey: any[] = [];
    if (fusionFilters[facetName] != null && fusionFilters[facetName] instanceof Array) {
      filterValuesForGivenKey = Array.from(fusionFilters[facetName]);
    }
    else if (fusionFilters[facetName] != null && typeof (fusionFilters[facetName]) == "string") {
      filterValuesForGivenKey = [fusionFilters[facetName]];
    }

    var filterValuesForGivenKeyFixedFilter: any[] = [];
    if (fusionFilters[fixed_filter_name] != null && fusionFilters[fixed_filter_name] instanceof Array) {
      filterValuesForGivenKeyFixedFilter = Array.from(fusionFilters[fixed_filter_name]);
    }
    else if (fusionFilters[fixed_filter_name] != null && typeof (fusionFilters[fixed_filter_name]) == "string") {
      filterValuesForGivenKeyFixedFilter = [fusionFilters[fixed_filter_name]];
    }

    const filterValueExists = filterValuesForGivenKey.includes(facetValue);
    if (!filterValueExists) {
      if (multiselect) {
        filterValuesForGivenKey.push(facetValue);
        const param: Param = {
          name: facetName,
          value: filterValuesForGivenKey
        }
        dispatch(setFilter(param));
      }
      else {
        const param: Param = {
          name: facetName,
          value: [facetValue]
        }
        dispatch(setFilter(param));
      }
    }

    if(!filterValuesForGivenKeyFixedFilter.includes(fixed_filter_value)){
      if (multiselect) {
        filterValuesForGivenKeyFixedFilter.push(fixed_filter_value);
        const param: Param = {
          name: fixed_filter_name,
          value: filterValuesForGivenKeyFixedFilter
        }
        dispatch(setFilter(param));
      }
      else {
        const param: Param = {
          name: fixed_filter_name,
          value: [fixed_filter_value]
        }
        dispatch(setFilter(param));
      }
    }
   
  }

  return(
    <li>
      <a href="/" onClick={handleClick}>{facetValue}</a>
    </li>
  )
}

function ExampleCarouselImage(props:any){
  const text = props.text
  const img = props.img

  return(
    <img src={img}/>
  )
}

function MyCarousel(){
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex:any) => {
    setIndex(selectedIndex);
  };
  return(
    <div className="py-3">
      <div className="spinner_text">Please wait while while we process your request...
      </div>
      <div className="py-2">
      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
      </Spinner>
      </div>
      <div className="py-3">
      <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item interval={3000}>
        <ExampleCarouselImage text="First slide" img="/img/spinner_slider/image.png" />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <ExampleCarouselImage text="Second slide"  img="/img/spinner_slider/image2.png"/>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <ExampleCarouselImage text="Third slide"  img="/img/spinner_slider/image3.png"/>
      </Carousel.Item>
    </Carousel>
    </div>
      </div>


  )
}

function App() {
  const [showMoreInfoPanel, setShowMoreInfoPanel] = useState(false);
  const setLastChatResponse = useAppSelector(selectLastChatResponse);
  const chatIsLoading = useAppSelector(selectChatIsLoading);
  const error_messsages = useAppSelector(getErrorMessages)
  const dispatch = useAppDispatch();
  const hidden = useAppSelector(selectHidden);
  const auth = useAppSelector(selectAuth)

  function createMarkup(message:string) {
    return {__html: message};
  }

  const handleModelSelectorToggle = (event: any) => {
    event.preventDefault();
    const newState = !hidden
    dispatch(setHidden(newState));
  };


  return (
    <>
      <Router>
        <main className="main-wrapper">
          {
        (auth.authenticated === false)
          ? <>
              <HeaderNoSearch />
              <Login/>
            </>
          : 
          <>
          <Header />
          <div className="container">
          <div className="row d-flex justify-content-center">

            <div className="col-6 py-3">


              <div className="error_messages">
              {error_messsages.filter((message:any)=>{return message.type !== 'alert-warning'}).map((message:any, index) => {
                return(
                  <div className={"alert " + message.type } role="alert" key={message.message}>
                    {message.message}
                  </div>
                )
              })}
              </div>


              <div className="chat-response px-5 ">
                {(!chatIsLoading)
                ? 
                <>
                <div dangerouslySetInnerHTML={createMarkup(setLastChatResponse)} />
                </>
                :
                <div className="text-center">
              <MyCarousel/>
                </div>               
                }

                
              </div>

              <div className="chat-response px-5 ">
                <AlternateOptions></AlternateOptions>
              </div>


            </div>

            </div>

            <div className="row">

            <div className="breadcrumb_content">
            <ul>
            <li><a href="/" onClick={handleModelSelectorToggle}>Home</a></li>
            <li><a href="/" onClick={(e) => { e.preventDefault(); setShowMoreInfoPanel(!showMoreInfoPanel) }}>Appliances</a></li>
            <li>All Full Size Refrigerators</li>
            </ul>
          </div>

            <div className="col-12">
              {(showMoreInfoPanel)
                ?
                <>
                  <ChatDebugPanel />
                </>
                : <>
                </>
              }
            </div>
            <ModelSelector/>
            <div className="shop_title"><h1>Refrigerators</h1></div>
            <Search />


         
          </div>
        </div>
        </>
        }

        </main>
        <script type="text/javascript" src="js/jquery.min.js"></script>
        <script type="text/javascript" src="js/bootstrap.min.js"></script>
        <script type="text/javascript" src="js/plugins.js"></script>
        <script type="text/javascript" src="js/scripts.js"></script>
      </Router>
    </>
  );
}

export default App;



