import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import configData from "../../system_config.json"
import { getCurrentEnvironment } from "./EnvironmentConfiguration"


const configDataOfAnyType: any = JSON.parse(JSON.stringify(configData));

export const ChatAPI = createApi({
  reducerPath: 'chatAPI',
  baseQuery: fetchBaseQuery(
    { baseUrl: configDataOfAnyType[getCurrentEnvironment()].conversation.url, 
      /*
      prepareHeaders: (headers, { getState }) => {
        //var basicAuth = "Basic " + btoa(configDataOfAnyType[getCurrentEnvironment()].fusion.username + ":" + configDataOfAnyType[getCurrentEnvironment()].fusion.password);
        //headers.set("Authorization", basicAuth);
        return headers
      },
      */
    }  
  ),
  endpoints: (builder) => ({
    chat: builder.mutation({
      query: (body) => ({
        url: '/conversation',
        method: 'POST',
        body,
      }),
    }),
    fusion: builder.mutation({
      query: (body) => ({
        url: '/fusion',
        method: 'POST',
        body,
      }),
    }),
    authenticate: builder.mutation({
      query: (body) => ({
        url: '/fusion/authenticate',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {useChatMutation, useFusionMutation, useAuthenticateMutation} = ChatAPI
