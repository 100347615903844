import React from 'react';
import {
  useLocation
} from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectHidden, selectModel, setModel
} from './searchSlice';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

export function ModelSelector(props: any) {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const dispatch = useAppDispatch();
  const hidden = useAppSelector(selectHidden);

  const handleChange = (event: any) => {
    if(event.target.checked){
     dispatch(setModel("4"));
    }
    else{
     dispatch(setModel("3.5"));
    }
  };

  const model = useAppSelector(selectModel);
  const checked = (model === '4') ? true : false;


  return (
    <>
    <div className={(hidden)? "model_selector_hidden": "model_selector_visible"}>
    <Stack direction="row" spacing={1} alignItems="center">
        <Typography>3.5</Typography>
        <Switch checked={checked}  onChange={handleChange}/>
        <Typography>4</Typography>
      </Stack>
    </div>
  </>
  )
}
export default ModelSelector
